
import { trigger } from "@angular/animations";
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CategoryDetailDto, CompanyTeamNumberExternalDataDto, CurrentCompanyDto, CurrentTeamDto, DayOfWeek, EnterpriseNumbersApi, GetEnterpriseNumberDto, GetNumberCalculationDefinitionDto, GetNumberDto, GetTeamUserResponsibilityDelegationDto, NumberDailyUpdateDefinitionDto, PlanNumbersApi, RecurrenceDto, RequireNote, SimpleCategoryDto, SimpleCompanyDto, SimpleCompanyTeamDto, SimpleDepartmentDto, SimpleNumberDto, SimpleSubCategoryDto, SimpleTeamDto, SimpleUserDto, UpdateNumberCalculationDefinitionDto, UpdateNumberDto, UpdateScheduleDto, UpdateTeamUserResponsibilityDelegationDto } from "@api";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest, debounceTime, defer, distinctUntilChanged, filter, identity, map, Observable, of, startWith, Subscription, switchMap, tap } from "rxjs";

import { CategoryRepository, DepartmentRepository, IQuarter, TeamRepository, TeamSettingsRepository, UserRepository } from "~repositories";
import { TeamContext, UserContext } from "~services/contexts";
import { NotificationService } from "~services/notification.service";
import { ErrorCode, WorkfactaError, wrapWorkfactaError } from "~shared/api-errors";
import { toFiscalQuarter } from "~shared/commonfunctions";
import { AutoSelectGroup } from "~shared/components/auto-select";
import { ButtonState } from "~shared/components/status-button/status-button.component";
import {
    CalculationType,
    CaptureMethod,
    DelegationResponsibility,
    NumberEntryType,
    NumberTargetType,
    NumberType,
    PlanningStatus,
    UpdateScheduleType
} from "~shared/enums";
import { WithDestroy } from "~shared/mixins";
import { fadeExpandAnimationBuilder, fadeInAnimationBuilder } from "~shared/util/animations";
import { retryWithDelay } from "~shared/util/caching";
import { decimalValidator, greaterThanControl, linkValidator } from "~shared/util/custom-validators";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";
import { isCalculatedNumbersEnabled, isDailyUpdatedNumbersEnabled, isDelegationEnabled, isExternalIntegrationsEnabled, isFlexibleSchedulingEnabled, isFlexibleSchedulingTeased, isNoteEnforcementEnabled, isTriggeredDiscussionsEnabled, isWorkInstructionEnabled } from "~shared/util/feature-helper";
import { setEnabledState } from "~shared/util/form-helper";
import { groupItemsByTeam } from "~shared/util/item-grouping";
import { sortNumberDefinition, supportsAddingNumbers } from "~shared/util/number-helper";
import { shareReplayUntil, tapFirst } from "~shared/util/rx-operators";
import { sortBoolean, sortMultiple } from "~shared/util/sorters";
import { compareTeams, getTeamSearchData } from "~shared/util/team-helper";
import { getCalculationTypeNameKey, getCaptureMethodNameKey, getDayOfWeekNameKey, getDelegationResponsibilityNameKey, getNumberTargetTypeNameKey, getNumberTypeNameKey, getPlanningStatusNameKey, getRequireNoteNameKey, getUpdateScheduleTypeNameKey } from "~shared/util/translation-helper";
import { bindTriggeredDiscussionValue, DEFAULT_TRIGGER_AFTER_UPDATES, getTriggeredDiscussionValue, TRIGGER_AFTER_UPDATES_OPTIONS, TriggeredDiscussionType } from "~shared/util/triggered-discussion-helper";
import { compareUsers, getUserName } from "~shared/util/user-helper";
import { valueAndChanges } from "~shared/util/util";

import { bindDailyChildren, DailyChildrenForm, forAllDays, getDailyChildrenValue } from "./daily-children-helper";
import { bindWeeklyTargets, getWeekTargetsValue, revalidateUpperTargets, updateTargetDisabledState, WeekTargetsForm } from "./week-targets-helper";

export type NumberInput = Partial<Pick<GetNumberDto, "description" | "type">>;

interface INumberDialogData {
    number?: GetNumberDto;
    isCopy?: boolean;
    team: SimpleCompanyTeamDto;
    financialYear: number;
    planningPeriod: number;
    readonly: boolean;
    input?: NumberInput;
}

export interface IAddNumberDialogData {
    company: CurrentCompanyDto | SimpleCompanyDto;
    team: CurrentTeamDto | SimpleTeamDto;
    financialYear: number;
    planningPeriod: number;
    input?: NumberInput;
}

const recurrenceDefinitionEqual = (left: RecurrenceDto, right: RecurrenceDto) =>
    left.type === right.type &&
    left.interval === right.interval &&
    left.index === right.index &&
    left.dayOfWeek === right.dayOfWeek &&
    left.referenceDate === right.referenceDate;

const updateDefinitionEqual = (left: UpdateScheduleDto, right: UpdateScheduleDto) =>
    left.type === right.type &&
    (
        left.recurrence === right.recurrence ||
        (!!left.recurrence && !!right.recurrence && recurrenceDefinitionEqual(left.recurrence, right.recurrence))
    );

const cloneSchedule = (schedule: UpdateScheduleDto): UpdateScheduleDto => ({
    type: schedule.type,
    recurrence: !schedule.recurrence ? undefined : {
        type: schedule.recurrence.type,
        interval: schedule.recurrence.interval,
        index: schedule.recurrence.index,
        dayOfWeek: schedule.recurrence.dayOfWeek,
        referenceDate: schedule.recurrence.referenceDate
    }
});

const scheduleValid = (schedule: UpdateScheduleDto): boolean => {
    if (schedule.type !== UpdateScheduleType.custom) return true;
    return !!schedule.recurrence;
};

const allCaptureMethods = [
    CaptureMethod.manual,
    CaptureMethod.automatic,
    CaptureMethod.calculated,
];

declare type CalculationSourceWarning = "number_type_mismatch" | "entry_type_mismatch";

declare type ExtendedScheduleType = UpdateScheduleType | "daily";

const allScheduleTypes: ExtendedScheduleType[] = [
    UpdateScheduleType.everyPeriod,
    UpdateScheduleType.everyMeeting,
    UpdateScheduleType.custom,
    "daily",
];

const fixUpdateSchedule = ({ type, recurrence }: { type?: ExtendedScheduleType; recurrence?: RecurrenceDto }): UpdateScheduleDto => ({
    type: type === "daily" ? UpdateScheduleType.everyPeriod : type,
    recurrence,
});

const defaultUpdateDays = [
    DayOfWeek.monday,
    DayOfWeek.tuesday,
    DayOfWeek.wednesday,
    DayOfWeek.thursday,
    DayOfWeek.friday,
];

const nonZero: ValidatorFn = control => {
    const value = control.value;
    if (value == null || value !== 0) return null;
    return { nonZero: true };
};

type CalulationNumberForm = FormGroup<{
    number: FormControl<SimpleNumberDto>;
    inverted: FormControl<boolean>;
}>;

const buildCalculationNumberForm = (number: SimpleNumberDto, inverted: boolean, disabled: boolean): CalulationNumberForm => new FormGroup({
    number: new FormControl({ value: number, disabled }, { nonNullable: true, validators: Validators.required }),
    inverted: new FormControl({ value: inverted, disabled }, { nonNullable: true }),
});

@Component({
    templateUrl: "./edit-number-dialog.component.html",
    styleUrls: ["./edit-number-dialog.component.scss"],
    animations: [
        trigger("fadeIn", fadeInAnimationBuilder()),
        trigger("fadeExpand", fadeExpandAnimationBuilder()),
    ],
})
export class EditNumberDialogComponent extends WithDestroy() implements OnInit, OnDestroy, AfterViewInit {

    buttonState: ButtonState;
    disableAnimations = true;

    readonly targetTypes = [
        NumberTargetType.aboveTarget,
        NumberTargetType.belowTarget,
        NumberTargetType.withinRange
    ];

    readonly planningStatuses = [
        PlanningStatus.draft,
        PlanningStatus.locked
    ];

    readonly numberTypes = [
        NumberType.normal,
        NumberType.currency,
        NumberType.percentage
    ];

    readonly entryTypes = [
        NumberEntryType.deltas,
        NumberEntryType.totals
    ];

    readonly calculationTypes = [
        CalculationType.sum,
        CalculationType.average,
        CalculationType.product,
    ];

    readonly dailyUpdateCalculationTypes = [
        CalculationType.sum,
        CalculationType.average,
        CalculationType.latest,
    ];

    readonly updateDayOptions = [
        DayOfWeek.monday,
        DayOfWeek.tuesday,
        DayOfWeek.wednesday,
        DayOfWeek.thursday,
        DayOfWeek.friday,
        DayOfWeek.saturday,
        DayOfWeek.sunday,
    ];

    readonly requireNoteOptions = [
        RequireNote.never,
        RequireNote.whenOffTarget,
        RequireNote.always,
    ];

    readonly delegationResponsibilities = [
        DelegationResponsibility.ownership,
        DelegationResponsibility.update,
    ];

    readonly triggerDiscussionAfterUpdatesOptions = TRIGGER_AFTER_UPDATES_OPTIONS;

    readonly teams$: Observable<SimpleCompanyTeamDto[]>;
    readonly users$: Observable<SimpleUserDto[]>;
    readonly captureMethods$: Observable<CaptureMethod[]>;
    readonly updateScheduleTypeOptions$: Observable<ExtendedScheduleType[]>;
    readonly departments$: Observable<SimpleDepartmentDto[]>;
    readonly categories$: Observable<CategoryDetailDto[] | null>;
    readonly subCategories$: Observable<SimpleSubCategoryDto[] | null>;
    readonly delegationTeams$: Observable<SimpleCompanyTeamDto[]>;
    readonly delegationUsers$: Observable<SimpleUserDto[]>;

    readonly unselectedNumbers$: Observable<GetEnterpriseNumberDto[]>;

    readonly descriptionControl = this.fb.control<string | null>(null, [Validators.required, Validators.maxLength(250)]);
    readonly teamControl = this.fb.control<SimpleCompanyTeamDto | null>(null, [Validators.required]);
    readonly quarterControl = this.fb.control<IQuarter | null>(null, [Validators.required]);
    readonly targetTypeControl = this.fb.nonNullable.control(NumberTargetType.aboveTarget, [Validators.required]);
    readonly targetLowerBoundControl = this.fb.control<number | null>(null, [decimalValidator]);
    readonly targetUpperBoundControl = this.fb.control<number | null>(null, [decimalValidator, greaterThanControl("targetLowerBound")]);
    readonly allowWeeklyTargetsControl = this.fb.nonNullable.control(false);
    readonly enteringTotalsControl = this.fb.nonNullable.control(false);
    readonly isRecurringControl = this.fb.nonNullable.control(true);
    readonly isPrivateControl = this.fb.nonNullable.control(false);
    readonly triggerDiscussionControl = this.fb.nonNullable.control(false);
    readonly triggerDiscussionTypeControl = this.fb.nonNullable.control<TriggeredDiscussionType>("offtarget");
    readonly triggerDiscussionAfterControl = this.fb.nonNullable.control<number>(DEFAULT_TRIGGER_AFTER_UPDATES);
    readonly numberTypeControl = this.fb.nonNullable.control(NumberType.normal, [Validators.required]);

    readonly scheduleTypeControl = this.fb.nonNullable.control<ExtendedScheduleType>(UpdateScheduleType.everyPeriod, [Validators.required]);
    readonly recurrenceControl = this.fb.nonNullable.control<RecurrenceDto | undefined>(undefined);

    readonly captureFrequencyControl = this.fb.group({
        type: this.scheduleTypeControl,
        recurrence: this.recurrenceControl
    });

    readonly dailyUpdateCalculationTypeControl = this.fb.nonNullable.control(CalculationType.sum, [Validators.required]);
    readonly updateDaysControl = this.fb.nonNullable.control([...defaultUpdateDays], [Validators.required, Validators.minLength(2)]);

    readonly dailyUpdateDefinitionControl = this.fb.group({
        calculationType: this.dailyUpdateCalculationTypeControl,
        days: this.updateDaysControl,
    });

    readonly calculationTypeControl = this.fb.nonNullable.control(CalculationType.sum, [Validators.required]);
    readonly calculationMultiplierControl = this.fb.control<number | null>(null, [decimalValidator, nonZero]);
    readonly calculationDivisorControl = this.fb.control<number | null>(null, [decimalValidator, nonZero]);
    readonly calculationNumbersControl = this.fb.array<CalulationNumberForm>([], { validators: Validators.required });

    readonly newCalculationNumberControl = this.fb.control<SimpleNumberDto | null>(null);

    readonly calculationDefinitionControl = this.fb.group({
        type: this.calculationTypeControl,
        multiplier: this.calculationMultiplierControl,
        divisor: this.calculationDivisorControl,
        numbers: this.calculationNumbersControl,
    });

    readonly ownerControl = this.fb.nonNullable.control<SimpleUserDto | null>(null, [Validators.required]);
    readonly updaterControl = this.fb.nonNullable.control<SimpleUserDto | null>(null, [Validators.required]);
    readonly captureMethodControl = this.fb.nonNullable.control(CaptureMethod.manual, [Validators.required]);
    readonly categoryControl = this.fb.nonNullable.control<string | null>(null);
    readonly subCategoryControl = this.fb.nonNullable.control<string | null>(null, [Validators.required]);
    readonly numberStatusControl = this.fb.nonNullable.control(PlanningStatus.draft, [Validators.required]);
    readonly departmentControl = this.fb.nonNullable.control<string | null>(null);
    readonly externalDataControl = this.fb.nonNullable.control<CompanyTeamNumberExternalDataDto | undefined>({
        value: undefined, disabled: true
    });

    readonly requireNoteControl = this.fb.nonNullable.control(RequireNote.never, [Validators.required]);
    readonly workInstructionControl = this.fb.control<string | null>(null, [Validators.maxLength(1000)]);
    readonly workInstructionLinkControl = this.fb.control<string | null>(null, [linkValidator]);

    readonly weekTargetsControl: WeekTargetsForm = this.fb.group({});

    readonly isDelegatedControl = this.fb.nonNullable.control(false);
    readonly delegationTeamControl = this.fb.control<SimpleCompanyTeamDto | null>(null, [Validators.required]);
    readonly delegationAssigneeControl = this.fb.control<SimpleUserDto | null>(null, [Validators.required]);
    readonly delegationResponsibilityControl = this.fb.nonNullable.control(DelegationResponsibility.ownership);
    readonly delegationControl = this.fb.group({
        team: this.delegationTeamControl,
        assignee: this.delegationAssigneeControl,
        responsibility: this.delegationResponsibilityControl,
    });

    readonly dailyChildrenControl: DailyChildrenForm = this.fb.group({});

    readonly form = this.fb.group({
        description: this.descriptionControl,
        team: this.teamControl,
        quarter: this.quarterControl,
        targetType: this.targetTypeControl,
        targetLowerBound: this.targetLowerBoundControl,
        targetUpperBound: this.targetUpperBoundControl,
        allowWeeklyTargets: this.allowWeeklyTargetsControl,
        enteringTotals: this.enteringTotalsControl,
        isRecurring: this.isRecurringControl,
        isPrivate: this.isPrivateControl,
        triggerDiscussion: this.triggerDiscussionControl,
        triggerDiscussionType: this.triggerDiscussionTypeControl,
        triggerDiscussionAfter: this.triggerDiscussionAfterControl,
        numberType: this.numberTypeControl,
        captureFrequency: this.captureFrequencyControl,
        dailyUpdateDefinition: this.dailyUpdateDefinitionControl,
        owner: this.ownerControl,
        updater: this.updaterControl,
        captureMethod: this.captureMethodControl,
        calculationDefinition: this.calculationDefinitionControl,
        category: this.categoryControl,
        subCategory: this.subCategoryControl,
        numberStatus: this.numberStatusControl,
        department: this.departmentControl,
        externalData: this.externalDataControl,
        requireNote: this.requireNoteControl,
        workInstruction: this.workInstructionControl,
        workInstructionLink: this.workInstructionLinkControl,
        weekTargets: this.weekTargetsControl,

        isDelegated: this.isDelegatedControl,
        delegation: this.delegationControl,

        dailyChildren: this.dailyChildrenControl,
    });

    weeks: number[] = [];

    readonly getUserName = getUserName;
    readonly getNumberTargetTypeNameKey = getNumberTargetTypeNameKey;
    readonly getNumberTypeNameKey = getNumberTypeNameKey;
    readonly getPlanningStatusNameKey = getPlanningStatusNameKey;
    readonly getCaptureMethodNameKey = getCaptureMethodNameKey;
    readonly getCalculationTypeNameKey = getCalculationTypeNameKey;
    readonly getDayOfWeekNameKey = getDayOfWeekNameKey;
    readonly getRequireNoteNameKey = getRequireNoteNameKey;
    readonly getDelegationResponsibilityNameKey = getDelegationResponsibilityNameKey;
    readonly compareTeams = compareTeams;
    readonly compareUsers = compareUsers;
    readonly getTeamSearchData = getTeamSearchData;

    get hasLowerTarget(): boolean {
        return this.targetTypeControl.value !== NumberTargetType.belowTarget;
    }

    get hasUpperTarget(): boolean {
        return this.targetTypeControl.value !== NumberTargetType.aboveTarget;
    }

    get hasRecurrence(): boolean {
        return this.scheduleTypeControl.value === UpdateScheduleType.custom;
    }

    get lowerTargetSet(): boolean {
        const target = this.targetLowerBoundControl.value;
        return target !== null && target !== undefined;
    }

    get upperTargetSet(): boolean {
        const target = this.targetUpperBoundControl.value;
        return target !== null && target !== undefined;
    }

    get supportsEnteringDeltas(): boolean {
        return supportsAddingNumbers(this.numberTypeControl.value);
    }

    get captureMethodIsAutomatic(): boolean {
        return this.captureMethodControl.value === CaptureMethod.automatic;
    }

    get captureMethodIsCalculated(): boolean {
        return this.captureMethodControl.value === CaptureMethod.calculated;
    }

    get originalCaptureMethod(): CaptureMethod {
        return this.number?.captureMethod ?? CaptureMethod.manual;
    }

    get originalDepartment(): SimpleDepartmentDto | undefined {
        return this.number?.department;
    }

    get originalCategory(): SimpleCategoryDto | undefined {
        return this.number?.category;
    }

    get originalSubCategory(): SimpleSubCategoryDto | undefined {
        return this.number?.category?.subCategory;
    }

    get originalDelegation(): GetTeamUserResponsibilityDelegationDto | undefined {
        return this.number?.delegation;
    }

    readonly schedulingVisible$: Observable<boolean>;
    readonly schedulingEnabled$: Observable<boolean>;
    readonly integrationsEnabled$: Observable<boolean>;
    readonly noteEnforcementEnabled$: Observable<boolean>;
    readonly workInstructionEnabled$: Observable<boolean>;
    readonly delegationVisible$: Observable<boolean>;
    readonly triggeredDiscussionsEnabled$: Observable<boolean>;

    readonly isSuperAdmin = this.userContext.isSuperAdmin;

    private readonly number?: GetNumberDto;

    private lastEnteringTotalsValue = false;

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly planNumbersApi: PlanNumbersApi,
        private readonly enterpriseNumbersApi: EnterpriseNumbersApi,
        private readonly teamRepository: TeamRepository,
        private readonly userRepository: UserRepository,
        private readonly departmentRepository: DepartmentRepository,
        private readonly categoryRepository: CategoryRepository,
        private readonly teamSettingsRepository: TeamSettingsRepository,
        private readonly userContext: UserContext,
        private readonly teamContext: TeamContext,
        private readonly dialogRef: MatDialogRef<EditNumberDialogComponent, GetNumberDto>,
        private readonly notificationService: NotificationService,
        private readonly fb: FormBuilder,
        private readonly translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) data: INumberDialogData
    ) {
        super();
        this.teamControl.setValue(data.team);
        this.number = data.isCopy ? undefined : data.number;
        this.quarterControl.setValue({
            financialYear: data.financialYear,
            quarter: data.planningPeriod,
        });

        if (this.number) {
            if (data.readonly || this.number.planningStatus === PlanningStatus.locked || this.number.isDelegated || this.number.source) {
                this.form.disable();
            } else {
                this.teamControl.disable();
                this.quarterControl.disable();
            }
        } else if (!this.teamContext.features.crossTeamFeaturesEnabled()) {
            this.teamControl.disable();
        }

        const companyTeamId$ = valueAndChanges(this.teamControl).pipe(
            filter(Boolean),
            map((team) => ({ companyId: team.company.id, teamId: team.id })),
            distinctUntilChanged((left, right) => left.companyId === right.companyId && left.teamId === right.teamId),
        );
        const companyId$ = companyTeamId$.pipe(
            map(({ companyId }) => companyId),
            distinctUntilChanged(),
        );

        this.teams$ = defer(() => this.teamRepository.getClientInstanceTeams(data.team.company.id));
        this.users$ = companyTeamId$.pipe(
            switchMap(({ companyId, teamId }) => this.userRepository.getTeamMembers(companyId, teamId)),
            // If we are creating a new number (and not copying), choose a default owner/updater after loading team members.
            data.number ? identity : tapFirst(this.setDefaultOwnerUpdater),
            tap(this.availableUsersChanged),
            shareReplayUntil(this.destroyed$),
        );
        this.departments$ = companyId$.pipe(
            switchMap(companyId => this.departmentRepository.getDepartments(companyId)),
            tap(this.availableDepartmentsChanged),
            shareReplayUntil(this.destroyed$),
        );
        this.categories$ = companyId$.pipe(
            switchMap(companyId => this.categoryRepository.getCategories(companyId)),
            tap(this.availableCategoriesChanged),
            // As we hide the categories field if we don't have any categories, we set to null to simplify view logic.
            map(categories => !categories.length ? null : categories),
            shareReplayUntil(this.destroyed$),
        );
        this.subCategories$ = combineLatest({
            categories: this.categories$,
            categoryId: valueAndChanges(this.categoryControl),
        }).pipe(
            map(({ categories, categoryId }) => categories?.find(c => c.id === categoryId)?.subCategories ?? []),
            tap(this.availableSubCategoriesChanged),
            // As we hide the subcategories field if we don't have any subcategories, we set to null to simplify view logic.
            map(subCategories => !subCategories.length ? null : subCategories),
            shareReplayUntil(this.destroyed$),
        );

        const allNumbers$ = combineLatest({
            companyId: companyId$,
            quarter: valueAndChanges(this.quarterControl).pipe(
                filter(Boolean),
            ),
        }).pipe(
            switchMap(({ companyId, quarter }) => this.enterpriseNumbersApi.getEnterpriseNumbersForPeriod(
                companyId, toFiscalQuarter(quarter)
            ).pipe(
                retryWithDelay(),
                startWith([]),
            )),
        );

        const availableNumbers$ = combineLatest({
            allNumbers: allNumbers$,
            isPrivate: valueAndChanges(this.isPrivateControl),
        }).pipe(
            map(({ allNumbers, isPrivate }) => {
                // We can't reference the delegated end of a number - we need to reference the main end.
                let availableNumbers = allNumbers.filter(n => !n.isDelegated);
                if (!isPrivate) {
                    // If the number is public, we can't reference private numbers.
                    availableNumbers = availableNumbers.filter(n => !n.isPrivate);
                }
                if (data.number) {
                    // We are updating a number. Ensure we cannot select ourselves.
                    const number = data.number;
                    availableNumbers = availableNumbers.filter(n =>
                        n.company.id !== number.company.id ||
                        n.team.id !== number.team.id ||
                        n.globalId !== number.globalId);
                    // Also ensure we don't include any numbers that are children of this number.
                    availableNumbers = availableNumbers.filter(n =>
                        !n.source ||
                        n.source.company.id !== number.company.id ||
                        n.source.team.id !== number.team.id ||
                        n.source.globalId !== number.globalId);
                }
                return availableNumbers;
            }),
            tap(this.availableCalculationNumbersChanged),
            map(numbers => numbers.sort(sortNumberDefinition.ascending())),
        );

        this.unselectedNumbers$ = combineLatest({
            availableNumbers: availableNumbers$,
            selectedNumbers: valueAndChanges(this.calculationNumbersControl)
        }).pipe(
            // Remove any numbers we have already selected
            map(({ availableNumbers, selectedNumbers }) => availableNumbers.filter(n =>
                !selectedNumbers.some(sn => sn.number && this.compareNumbers(sn.number, n)))),
            shareReplayUntil(this.destroyed$),
        );

        this.delegationTeams$ = companyTeamId$.pipe(
            switchMap(({ companyId, teamId }) => this.teamSettingsRepository.getDelegationTeams(companyId, teamId)),
            tap(this.availableDelegatedTeamsChanged),
            shareReplayUntil(this.destroyed$),
        );
        this.delegationUsers$ = valueAndChanges(this.delegationTeamControl).pipe(
            switchMap(team => !team ? of([]) : this.userRepository.getTeamMembers(team.company.id, team.id)),
            tap(this.availableDelegatedUsersChanged),
            shareReplayUntil(this.destroyed$),
        );

        this.schedulingVisible$ = this.teamContext.companyTeam$.pipe(
            map(ct => isFlexibleSchedulingEnabled(ct) || isFlexibleSchedulingTeased(ct)),
        );
        this.schedulingEnabled$ = this.teamContext.companyTeam$.pipe(map(isFlexibleSchedulingEnabled));

        this.integrationsEnabled$ = this.teamContext.companyTeam$.pipe(map(isExternalIntegrationsEnabled));

        this.captureMethods$ = this.teamContext.companyTeam$.pipe(
            map(isCalculatedNumbersEnabled),
            distinctUntilChanged(),
            map(calculatedEnabled => calculatedEnabled ? allCaptureMethods :
                allCaptureMethods.filter(cm => cm !== CaptureMethod.calculated)),
            tap(this.availableCaptureMethodsChanged),
            shareReplayUntil(this.destroyed$),
        );

        this.updateScheduleTypeOptions$ = this.teamContext.companyTeam$.pipe(
            map(isDailyUpdatedNumbersEnabled),
            distinctUntilChanged(),
            map(dailyUpdatesEnabled => dailyUpdatesEnabled ? allScheduleTypes :
                allScheduleTypes.filter(t => t !== "daily")),
            tap(this.availableScheduleTypesChanged),
            shareReplayUntil(this.destroyed$),
        );

        this.noteEnforcementEnabled$ = this.teamContext.companyTeam$.pipe(
            map(isNoteEnforcementEnabled),
            shareReplayUntil(this.destroyed$),
        );

        this.workInstructionEnabled$ = this.teamContext.companyTeam$.pipe(
            map(isWorkInstructionEnabled),
            shareReplayUntil(this.destroyed$),
        );

        this.delegationVisible$ = this.teamContext.companyTeam$.pipe(
            map(isDelegationEnabled),
            switchMap(enabled => {
                // If the form is disabled, show the delegation section if enabled and set but do no further processing
                if (this.form.disabled) return of(enabled && !!this.number?.delegation);
                // Delegation is otherwise visible if enabled and there are some delegation teams set
                const visible$ = !enabled ? of(false) : this.delegationTeams$.pipe(map(teams => !!teams.length));
                return visible$.pipe(
                    tap(visible => {
                        if (!visible) {
                            this.isDelegatedControl.setValue(false);
                            this.isDelegatedControl.disable();
                            this.delegationControl.disable();
                        } else {
                            if (this.form.enabled) {
                                this.isDelegatedControl.enable();
                            }
                        }
                    }),
                    // While the delegation teams are loading, should be based on whether delegation settings currently exist.
                    startWith(!!this.number?.delegation),
                );
            }),
            shareReplayUntil(this.destroyed$),
        );

        this.triggeredDiscussionsEnabled$ = this.teamContext.companyTeam$.pipe(
            map(isTriggeredDiscussionsEnabled),
            shareReplayUntil(this.destroyed$),
        );

        if (data.number) {
            this.bindNumber(data.number);
        } else if (data.input) {
            this.bindInput(data.input);
        }
    }

    static openForAdd(dialog: MatDialog, data: IAddNumberDialogData) {
        return this.openInternal(dialog, {
            number: undefined,
            team: {
                id: data.team.id,
                name: data.team.name,
                company: {
                    id: data.company.id,
                    name: data.company.name,
                    clientId: data.company.clientId,
                },
            },
            financialYear: data.financialYear,
            planningPeriod: data.planningPeriod,
            isCopy: false,
            readonly: false,
            input: data.input,
        });
    }

    static openForEdit(dialog: MatDialog, number: GetNumberDto, isCopy = false, readonly = false) {
        const { company, team } = isCopy ? getDelegatedItemCompanyTeam(number) : number;
        return this.openInternal(dialog, {
            number: number,
            team: {
                id: team.id,
                name: team.name,
                company: {
                    id: company.id,
                    name: company.name,
                    clientId: company.clientId,
                },
            },
            financialYear: number.financialYear,
            planningPeriod: number.planningPeriod,
            isCopy: isCopy,
            readonly,
        });
    }

    private static openInternal(dialog: MatDialog, data: INumberDialogData) {
        return dialog.open<EditNumberDialogComponent, INumberDialogData, GetNumberDto>(EditNumberDialogComponent, {
            width: "1050px",
            data: data
        });
    }

    ngOnInit(): void {

        this.subscriptions.add(valueAndChanges(this.captureMethodControl).subscribe(this.handleCaptureMethodChange));
        this.subscriptions.add(valueAndChanges(this.scheduleTypeControl).subscribe(this.handleScheduleTypeChange));
        this.subscriptions.add(valueAndChanges(this.updateDaysControl).subscribe(this.handleUpdateDaysChange));
        this.subscriptions.add(valueAndChanges(this.numberTypeControl).subscribe(this.handleNumberTypeChange));
        this.subscriptions.add(combineLatest({
            isOwnedByMe: valueAndChanges(this.ownerControl).pipe(
                map(owner => owner?.userId === this.userContext.userId())),
            isUpdatedByMe: valueAndChanges(this.updaterControl).pipe(
                map(updater => updater?.userId === this.userContext.userId())),
            isDelegated: valueAndChanges(this.isDelegatedControl),
            captureMethod: valueAndChanges(this.captureMethodControl),
        }).subscribe(({ isOwnedByMe, isUpdatedByMe, isDelegated, captureMethod }) => {
            if (!this.form.enabled) return;
            if ((isOwnedByMe || isUpdatedByMe) && !isDelegated && captureMethod !== CaptureMethod.automatic) {
                this.isPrivateControl.enable();
            } else {
                this.isPrivateControl.disable();
                this.isPrivateControl.setValue(false);
            }
        }));
        this.subscriptions.add(valueAndChanges(this.isDelegatedControl).subscribe(isDelegated => {
            if (!isDelegated) {
                this.delegationControl.disable();
                this.delegationTeamControl.setValue(null);
                this.delegationAssigneeControl.setValue(null);
                this.updaterControl.setValidators([Validators.required]);
                forAllDays(this.dailyChildrenControl, (_, dayForm) => dayForm.controls.updater.setValidators([Validators.required]));
            } else {
                // When delegating the number, we don't need to specify an updater
                this.updaterControl.setValidators([]);
                forAllDays(this.dailyChildrenControl, (_, dayForm) => dayForm.controls.updater.setValidators([]));
                if (this.form.enabled) {
                    this.delegationControl.enable();
                    if (this.captureMethodControl.value !== CaptureMethod.manual) {
                        this.delegationResponsibilityControl.disable();
                    }
                }
            }
            this.updaterControl.updateValueAndValidity();
            forAllDays(this.dailyChildrenControl, (_, dayForm) => dayForm.controls.updater.updateValueAndValidity());
        }));
        this.subscriptions.add(valueAndChanges(this.isPrivateControl).subscribe(isPrivate => {
            if (!this.form.enabled) return;
            const control = this.triggerDiscussionControl;
            if (control.disabled === isPrivate) return;
            if (isPrivate) {
                // We cannot have a private goal with triggered discussions
                control.setValue(false);
                control.disable();
            } else {
                control.enable();
            }
        }));
        this.subscriptions.add(valueAndChanges(this.triggerDiscussionControl).subscribe(this.handleTriggerDiscussionChange));
        this.subscriptions.add(valueAndChanges(this.triggerDiscussionTypeControl).subscribe(this.handleTriggerDiscussionTypeChange));

        this.subscriptions.add(this.targetLowerBoundControl.valueChanges.subscribe(() => {
            this.targetUpperBoundControl.updateValueAndValidity();
        }));
        this.subscriptions.add(this.targetTypeControl.valueChanges.subscribe(this.handleTargetTypeChange));

        this.subscriptions.add(valueAndChanges(this.newCalculationNumberControl).pipe(
            filter(Boolean),
        ).subscribe(number => {
            this.calculationNumbersControl.push(buildCalculationNumberForm(number, false, this.calculationNumbersControl.disabled));
            this.newCalculationNumberControl.reset();
        }));

        if (this.form.enabled) {
            this.subscriptions.add(combineLatest({
                team: valueAndChanges(this.teamControl).pipe(
                    filter(Boolean),
                ),
                schedule: valueAndChanges(this.captureFrequencyControl).pipe(
                    map(fixUpdateSchedule),
                    filter(scheduleValid),
                    map(cloneSchedule), // Prevents mutations to the original schedule from breaking the distinct check
                    distinctUntilChanged(updateDefinitionEqual)
                ),
                quarter: valueAndChanges(this.quarterControl).pipe(
                    filter(Boolean),
                ),
            }).pipe(
                debounceTime(100),
                switchMap(({ team, schedule, quarter }) =>
                    this.planNumbersApi.getExpectedScheduleWeeks(
                        team.company.id,
                        team.id,
                        toFiscalQuarter(quarter),
                        schedule).pipe(retryWithDelay()))
            ).subscribe(weeks => {
                this.weeks = weeks.filter((v, i, a) => a.indexOf(v) === i); // Removes duplicates from the list
                // Add any weeks we have not previously seen to the form.
                bindWeeklyTargets(
                    this.weeks,
                    this.form.controls,
                    this.subscriptions);
                forAllDays(this.dailyChildrenControl, (_, dayForm) =>
                    bindWeeklyTargets(
                        this.weeks,
                        dayForm.controls,
                        this.subscriptions));
            }));
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.disableAnimations = false, 0);
    }

    save() {
        this.revalidateAllUpperTargets();
        if (!this.form.valid || this.buttonState) return;

        this.buttonState = "loading";

        const dto: UpdateNumberDto = {
            ownerUserId: this.ownerControl.value?.userId ?? "",
            updaterUserId: this.updaterControl.value?.userId ?? undefined,
            departmentId: this.departmentControl.value ?? undefined,
            categoryId: this.categoryControl.value ?? undefined,
            subCategoryId: this.subCategoryControl.value ?? undefined,
            description: this.descriptionControl.value ?? "",
            type: this.numberTypeControl.value,
            entryType: this.enteringTotalsControl.value ? NumberEntryType.totals : NumberEntryType.deltas,
            captureMethod: this.captureMethodControl.value,
            externalData: this.captureMethodControl.value === CaptureMethod.automatic ? this.externalDataControl.value : undefined,
            numberStatus: this.numberStatusControl.value,
            targetType: this.targetTypeControl.value,
            isRecurring: this.isRecurringControl.value,
            isPrivate: this.isPrivateControl.value,
            calculationDefinition: this.getCalculationDefinition(),
            requireNote: this.requireNoteControl.value,
            workInstruction: this.workInstructionControl.value ?? undefined,
            workInstructionLink: (this.workInstructionControl.value && this.workInstructionLinkControl.value) ?? undefined,
            detectProblemAfterUpdates: getTriggeredDiscussionValue(this.form.controls),
            allowWeeklyTargetOverrides: this.allowWeeklyTargetsControl.value,
            target: {
                lowerBound: this.hasLowerTarget ? this.targetLowerBoundControl.value ?? undefined : undefined,
                upperBound: this.hasUpperTarget ? this.targetUpperBoundControl.value ?? undefined : undefined,
            },
            weekTargets: getWeekTargetsValue(this.weeks, this.targetTypeControl.value, this.weekTargetsControl),
            scheduleDefinition: fixUpdateSchedule(this.captureFrequencyControl.value),
            dailyUpdateDefinition: this.getDailyUpdateDefinition(),
            children: this.scheduleTypeControl.value !== "daily" ? undefined :
                getDailyChildrenValue(this.updateDaysControl.value, this.weeks, this.targetTypeControl.value, this.dailyChildrenControl),

            delegation: this.getDelegation(),
        };

        let obs$: Observable<GetNumberDto>;
        if (this.number) {
            obs$ = this.planNumbersApi.updateNumber(
                this.number.company.id,
                this.number.team.id,
                toFiscalQuarter({ financialYear: this.number.financialYear, quarter: this.number.planningPeriod }),
                this.number.id,
                dto
            );
        } else {
            const team = this.teamControl.value;
            obs$ = this.planNumbersApi.addNumber(
                team?.company.id ?? "",
                team?.id ?? "",
                toFiscalQuarter(this.quarterControl.value as IQuarter),
                dto
            );
        }

        obs$.pipe(wrapWorkfactaError()).subscribe({
            next: result => {
                this.buttonState = "success";
                setTimeout(() => {
                    this.dialogRef.close(result);
                }, 1000);
            },
            error: error => {
                this.buttonState = "error";
                setTimeout(() => {
                    this.buttonState = undefined;
                }, 2000);
                if (error instanceof WorkfactaError) {
                    switch (error.status) {
                        case 409:
                            switch (error.code) {
                                case ErrorCode.planCapReached:
                                    this.form.setErrors({ capReached: true });
                                    return;
                                case ErrorCode.planCapExceeded:
                                    this.form.setErrors({ capExceeded: true });
                                    return;
                                case ErrorCode.calculationCircularReference:
                                    this.calculationNumbersControl.setErrors({ circularReference: true });
                                    return;
                            }
                    }
                }
                this.notificationService.errorUnexpected();
            },
        });
    }

    getDepartmentId = (department: SimpleDepartmentDto | null | undefined): string => department?.id ?? "";
    getDepartmentName = (department: SimpleDepartmentDto | null | undefined): string => department?.name ?? "";
    getCategoryId = (category: CategoryDetailDto): string => category?.id ?? "";
    getCategoryDisplay = (category: CategoryDetailDto | null | undefined) => category?.description ?? "";
    getSubCategoryId = (subCategory: SimpleSubCategoryDto): string => subCategory?.id ?? "";
    getSubCategoryDisplay = (subCategory: SimpleSubCategoryDto | null | undefined): string => subCategory?.description ?? "";
    getTeamDisplay = (team: SimpleCompanyTeamDto | null | undefined): string => team?.name ?? "";

    targetIsSet = (target: number | null | undefined): boolean => target !== null && target !== undefined;

    isCalculationTypeSum = (type: CalculationType): boolean => type === CalculationType.sum;
    isCalculationTypeProduct = (type: CalculationType): boolean => type === CalculationType.product;
    calculationCanInvert = (type: CalculationType): boolean => type === CalculationType.sum || type === CalculationType.product;

    compareNumbers = (o1: SimpleNumberDto, o2: SimpleNumberDto) => {
        if (!o1 && !o2) return true;
        if (!o1 || !o2) return false;
        return o1.globalId === o2.globalId && o1.team.id === o2.team.id && o1.company.id === o2.company.id;
    };

    getNumberWarnings = (number: GetEnterpriseNumberDto): CalculationSourceWarning[] | null => {
        const warnings: CalculationSourceWarning[] = [];
        const numberType = this.numberTypeControl.value;
        const entryType = this.enteringTotalsControl.value ? NumberEntryType.totals : NumberEntryType.deltas;
        if (number.type !== numberType) warnings.push("number_type_mismatch");
        if (number.entryType !== entryType) warnings.push("entry_type_mismatch");
        return !warnings.length ? null : warnings;
    };

    getUpdateScheduleTypeNameKey = (type: ExtendedScheduleType): string => {
        if (type === "daily") return "numbers.dailyUpdates.daily";
        return getUpdateScheduleTypeNameKey(type);
    };

    getNumberDisplayFunc = (number: GetEnterpriseNumberDto | null | undefined): string => !number ? "" :
        number.description + (number.updateDay != null ? ` (${this.translate.instant(getDayOfWeekNameKey(number.updateDay))})` : "");

    getNumberSearchData = (number: GetEnterpriseNumberDto): string =>
        `${this.getNumberDisplayFunc(number)} ${number.team.name} ${number.company.name}`;

    groupNumbers = (numbers: GetEnterpriseNumberDto[]): AutoSelectGroup<GetEnterpriseNumberDto>[] =>
        groupItemsByTeam(numbers).map(t => ({
            id: `${t.company.id}_${t.team.id}`,
            name: `${t.company.name}: ${t.team.name}`,
            options: t.items,
        }));

    private bindNumber = (n: GetNumberDto) => {
        this.descriptionControl.setValue(n.description);
        this.quarterControl.setValue({
            financialYear: n.financialYear,
            quarter: n.planningPeriod,
        });
        this.targetTypeControl.setValue(n.targetType);
        this.targetLowerBoundControl.setValue(n.target.lowerBound ?? null);
        this.targetUpperBoundControl.setValue(n.target.upperBound ?? null);
        this.allowWeeklyTargetsControl.setValue(n.allowWeeklyTargetOverrides);
        this.enteringTotalsControl.setValue(n.entryType === NumberEntryType.totals);
        this.isRecurringControl.setValue(n.isRecurring);
        this.isPrivateControl.setValue(n.isPrivate);
        bindTriggeredDiscussionValue(this.form.controls, n.detectProblemAfterUpdates);
        this.numberTypeControl.setValue(n.type);
        this.scheduleTypeControl.setValue(
            n.dailyUpdateDefinition ? "daily" : (n.scheduleDefinition.type ?? UpdateScheduleType.everyPeriod));
        this.recurrenceControl.setValue(n.scheduleDefinition.recurrence);
        this.ownerControl.setValue(n.owner ?? null);
        this.updaterControl.setValue(n.updater ?? null);
        this.captureMethodControl.setValue(n.captureMethod);
        this.categoryControl.setValue(n.category?.id ?? null);
        this.subCategoryControl.setValue(n.category?.subCategory?.id ?? null);
        this.numberStatusControl.setValue(n.planningStatus);
        this.departmentControl.setValue(n.department?.id ?? null);
        this.externalDataControl.setValue(n.externalData);
        this.requireNoteControl.setValue(n.requireNote);
        this.workInstructionControl.setValue(n.workInstruction ?? null);
        this.workInstructionLinkControl.setValue(n.workInstructionLink ?? null);

        if (n.delegation) this.bindDelegation(n.delegation);
        if (n.dailyUpdateDefinition) this.bindDailyUpdateDefinition(n.dailyUpdateDefinition);
        if (n.calculationDefinition) this.bindCalculationDefinition(n.calculationDefinition);

        const weeks: number[] = [];
        for (const weekStr in n.weekTargets) {
            if (!Object.prototype.hasOwnProperty.call(n.weekTargets, weekStr)) continue;
            const week = parseInt(weekStr, 10);
            weeks.push(week);
        }
        this.weeks = weeks;
        bindWeeklyTargets(
            weeks,
            this.form.controls,
            this.subscriptions,
            n.weekTargets,
        );

        if (n.dailyUpdateDefinition && n.children) {
            bindDailyChildren(
                n.dailyUpdateDefinition.days,
                weeks,
                this.form.controls,
                this.subscriptions,
                n.children,
            );
        }

        if (!n.canEdit) {
            this.form.disable();
            this.form.setErrors({ capExceeded: true });
        }
    };

    private bindInput = (input: NumberInput) => {
        if (input.description) this.descriptionControl.setValue(input.description);
        if (input.type != null) this.numberTypeControl.setValue(input.type);
    };

    private revalidateAllUpperTargets = () => {
        revalidateUpperTargets(this.form.controls);
        forAllDays(this.dailyChildrenControl, (_, dayForm) => {
            revalidateUpperTargets(dayForm.controls);
        });
    };

    private handleTargetTypeChange = () => {
        if (this.form.disabled) return;
        const targetType = this.targetTypeControl.value;
        updateTargetDisabledState(targetType, this.form.controls);
        forAllDays(this.dailyChildrenControl, (_, dayForm) => {
            updateTargetDisabledState(targetType, dayForm.controls);
        });
    };

    private bindDelegation = (delegation: GetTeamUserResponsibilityDelegationDto) => {
        this.isDelegatedControl.setValue(true);
        this.delegationTeamControl.setValue({
            ...delegation.team,
            company: delegation.company,
        });
        this.delegationAssigneeControl.setValue(delegation.assignee ?? null);
        this.delegationResponsibilityControl.setValue(delegation.responsibility);
    };

    private getDelegation = (): UpdateTeamUserResponsibilityDelegationDto | undefined => {
        if (!this.isDelegatedControl.value) return undefined;
        const team = this.delegationTeamControl.value;
        const assignee = this.delegationAssigneeControl.value;
        if (!team || !assignee) return undefined;
        return {
            companyId: team.company.id,
            teamId: team.id,
            assigneeUserId: assignee.userId,
            responsibility: this.delegationResponsibilityControl.value,
        };
    };

    private bindDailyUpdateDefinition = (definition: NumberDailyUpdateDefinitionDto) => {
        this.dailyUpdateCalculationTypeControl.setValue(definition.calculationType);
        this.updateDaysControl.setValue([...definition.days]);
    };

    private getDailyUpdateDefinition = (): NumberDailyUpdateDefinitionDto | undefined => {
        if (this.scheduleTypeControl.value !== "daily") return undefined;
        return {
            calculationType: this.dailyUpdateCalculationTypeControl.value,
            days: this.updateDaysControl.value,
        };
    };

    private bindCalculationDefinition = (definition: GetNumberCalculationDefinitionDto) => {
        this.calculationTypeControl.setValue(definition.type);
        this.calculationMultiplierControl.setValue(definition.multiplier ?? null);
        this.calculationDivisorControl.setValue(definition.divisor ?? null);

        for (const number of definition.numbers.sort(sortMultiple(
            // Any inverted numbers should come last
            sortBoolean.ascending(n => n.inverted),
            sortNumberDefinition.ascending(n => n.number),
        ))) {
            this.calculationNumbersControl.push(buildCalculationNumberForm(number.number, number.inverted,
                this.calculationNumbersControl.disabled));
        }
    };

    private getCalculationDefinition = (): UpdateNumberCalculationDefinitionDto | undefined => {
        if (this.captureMethodControl.value !== CaptureMethod.calculated) return undefined;
        const calculationType = this.calculationTypeControl.value;
        const supportsInversion = calculationType === CalculationType.sum || calculationType === CalculationType.product;
        return {
            type: calculationType,
            multiplier: this.calculationMultiplierControl.value ?? undefined,
            divisor: this.calculationDivisorControl.value ?? undefined,
            numbers: this.calculationNumbersControl.getRawValue().map(({ number, inverted }) => ({
                companyId: number.company.id,
                teamId: number.team.id,
                globalId: number.globalId,
                inverted: inverted && supportsInversion,
            })),
        };
    };

    private availableCaptureMethodsChanged = (methods: CaptureMethod[]): void => {
        if (!methods.includes(this.captureMethodControl.value)) {
            this.captureMethodControl.setValue(methods[0]);
        }
    };

    private availableScheduleTypesChanged = (types: ExtendedScheduleType[]): void => {
        if (!types.includes(this.scheduleTypeControl.value)) {
            this.scheduleTypeControl.setValue(UpdateScheduleType.everyPeriod);
        }
    };

    private setDefaultOwnerUpdater = (users: SimpleUserDto[]): void => {
        // Ensure the number is a new number and the owner is not yet set.
        if (this.number || this.ownerControl.value) return;

        const currentUserId = this.userContext.userId();
        // Ensure the user is in the current team.
        const currentUser = users.find(u => u.userId === currentUserId);
        if (currentUser) {
            this.ownerControl.setValue(currentUser);
            if (this.updaterControl.enabled) {
                this.updaterControl.setValue(currentUser);
            }
        }
    };

    private availableUsersChanged = (users: SimpleUserDto[]) => {
        const currentOwner = this.ownerControl.value;
        if (currentOwner && !users.some(x => compareUsers(x, currentOwner))) {
            this.ownerControl.setValue(null);
        }
        const currentUpdater = this.updaterControl.value;
        if (currentUpdater && !users.some(x => compareUsers(x, currentUpdater))) {
            this.updaterControl.setValue(null);
        }
        forAllDays(this.dailyChildrenControl, (_, dayForm) => {
            const updaterControl = dayForm.controls.updater;
            const currentDayUpdater = updaterControl.value;
            if (currentDayUpdater && !users.some(x => compareUsers(x, currentDayUpdater))) {
                updaterControl.setValue(null);
            }
        });
    };

    private availableDepartmentsChanged = (departments: SimpleDepartmentDto[]) => {
        if (this.departmentControl.value && !departments.some(d => d.id === this.departmentControl.value)) {
            this.departmentControl.setValue(null);
        }
    };

    private availableCategoriesChanged = (categories: CategoryDetailDto[]) => {
        if (!categories.length) {
            // Ensures even if the list of sub-categories is not subscribed to (due to no categories)
            // the control will be cleared and disabled appropriately.
            this.subCategoryControl.setValue(null);
            this.subCategoryControl.disable();
        }
        if (this.categoryControl.value && !categories.some(x => x.id === this.categoryControl.value)) {
            this.categoryControl.setValue(null);
        }
    };

    private availableSubCategoriesChanged = (subCategories: SimpleSubCategoryDto[]) => {
        if (!subCategories.length) {
            this.subCategoryControl.setValue(null);
            this.subCategoryControl.disable();
            return;
        }

        if (this.form.enabled) this.subCategoryControl.enable();
        if (subCategories.length === 1) {
            this.subCategoryControl.setValue(subCategories[0].id ?? null);
        } else if (this.subCategoryControl.value && !subCategories.some(x => x.id === this.subCategoryControl.value)) {
            this.subCategoryControl.setValue(null);
        }
    };

    private handleUpdateDaysChange = (days: DayOfWeek[]) => {
        if (this.scheduleTypeControl.value !== "daily") return;
        bindDailyChildren(
            days,
            this.weeks,
            this.form.controls,
            this.subscriptions,
            /* children: */ undefined,
        );
    };

    private availableCalculationNumbersChanged = (numbers: GetEnterpriseNumberDto[]) => {
        // We don't want to remove any numbers that were already selected.
        const allNumbers = [...numbers, ...(this.number?.calculationDefinition?.numbers.map(n => n.number) ?? [])];
        // Note: we loop backwards so that removing items doesn't affect the loop.
        for (let i = this.calculationNumbersControl.length - 1; i >= 0; i--) {
            const sn = this.calculationNumbersControl.at(i).controls.number.value;
            if (!allNumbers.some(n => this.compareNumbers(sn, n))) {
                this.calculationNumbersControl.removeAt(i);
            }
        }
    };

    private availableDelegatedTeamsChanged = (teams: SimpleCompanyTeamDto[]) => {
        const currentTeam = this.delegationTeamControl.value;
        if (currentTeam && !teams.some(t => compareTeams(t, currentTeam))) {
            this.delegationTeamControl.setValue(null);
        }
    };

    private availableDelegatedUsersChanged = (users: SimpleUserDto[]) => {
        const currentAssignee = this.delegationAssigneeControl.value;
        if (currentAssignee && !users.some(x => compareUsers(x, currentAssignee))) {
            this.delegationAssigneeControl.setValue(null);
        }
    };

    private handleCaptureMethodChange = (captureMethod: CaptureMethod) => {
        switch (captureMethod) {
            case CaptureMethod.automatic:
                this.updaterControl.setValue(null);
                this.updaterControl.disable();
                forAllDays(this.dailyChildrenControl, (_, dayForm) => {
                    dayForm.controls.updater.setValue(null);
                    dayForm.controls.updater.disable();
                });
                this.calculationDefinitionControl.disable();
                this.delegationResponsibilityControl.setValue(DelegationResponsibility.ownership);
                this.delegationResponsibilityControl.disable();
                if (this.form.enabled) this.externalDataControl.enable();
                break;
            case CaptureMethod.calculated:
                this.updaterControl.setValue(null);
                this.updaterControl.disable();
                forAllDays(this.dailyChildrenControl, (_, dayForm) => {
                    dayForm.controls.updater.setValue(null);
                    dayForm.controls.updater.disable();
                });
                this.externalDataControl.disable();
                this.delegationResponsibilityControl.setValue(DelegationResponsibility.ownership);
                this.delegationResponsibilityControl.disable();
                if (this.form.enabled) this.calculationDefinitionControl.enable();
                break;
            case CaptureMethod.manual:
                if (this.form.enabled) {
                    this.updaterControl.enable();
                    forAllDays(this.dailyChildrenControl, (_, dayForm) => {
                        dayForm.controls.updater.enable();
                    });
                    if (this.isDelegatedControl.value) {
                        this.delegationResponsibilityControl.enable();
                    }
                }
                this.externalDataControl.disable();
                this.calculationDefinitionControl.disable();
                break;
        }
    };

    private handleScheduleTypeChange = (type: ExtendedScheduleType) => {
        if (!this.form.enabled) return;
        setEnabledState(this.dailyUpdateDefinitionControl, type === "daily");
    };

    private handleNumberTypeChange = (type: NumberType) => {
        if (!supportsAddingNumbers(type)) {
            if (this.enteringTotalsControl.enabled) {
                this.lastEnteringTotalsValue = this.enteringTotalsControl.value;
                this.enteringTotalsControl.disable();
                this.enteringTotalsControl.setValue(true);
            }
        } else {
            if (this.enteringTotalsControl.disabled) {
                this.enteringTotalsControl.setValue(this.lastEnteringTotalsValue);
                if (this.form.enabled) this.enteringTotalsControl.enable();
            }
        }
    };

    private handleTriggerDiscussionChange = (enabled: boolean) => {
        if (!this.form.enabled) return;
        setEnabledState(this.triggerDiscussionTypeControl, enabled);
        setEnabledState(this.triggerDiscussionAfterControl, enabled && this.triggerDiscussionTypeControl.value === "offtarget");
    };

    private handleTriggerDiscussionTypeChange = (type: TriggeredDiscussionType) => {
        if (!this.form.enabled) return;
        setEnabledState(this.triggerDiscussionAfterControl, type === "offtarget" && this.triggerDiscussionControl.value);
    };
}
